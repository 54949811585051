<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        {{ partner.label }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-container
        fluid
        class="pa-0"
      >
        <v-row
          align="center"
          justify="end"
        >
          <v-col class="pa-0">
            <v-autocomplete
              dense
              hide-details
              background-color="#dddddd"
              solo
              :items="availableDiscounts"
              no-data-text="Min discount is 0, max is 100"
              placeholder="Discount"
              v-model="partner.discountPercent"
              flat
              :disabled="disabled"
            >
              <template v-slot:append>
                %
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
	name: "YessPartnerListItem",
	props: {
		partner: {
      type: Object,
      default: () => {}
    },
		disabled: {
      type: Boolean
    }
	},
	computed: {
		availableDiscounts() {
			return Array.from(Array(101).keys())
		}
	}
}
</script>

<style scoped>

</style>