<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t('yessPartnersDiscounts') }} <v-spacer /><v-btn
              icon
              :disabled="loading || disabled"
              @click="reload"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="py-0">
            <div
              class="text-center"
              v-if="loading"
            >
              <v-progress-circular
                indeterminate
                size="48"
              />
            </div>
            <div
              class="text-center pt-4 title"
              v-else-if="!partners || partners.length <= 0"
            > 
              {{ $t('sorryNoPartnersAtTheMoment') }}
            </div>
            <v-list v-else>
              <v-divider />
              <template v-for="partner in partners">
                <YessPartnerListItem
                  :key="partner.uuid"
                  :partner="partner"
                  :disabled="disabled"
                />
                <v-divider :key="'d-' + partner.uuid" />
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-container
              class="pa-0"
              fluid
            >
              <v-row justify="end">
                <v-col
                  cols="12"
                  md="auto"
                  class="py-0"
                >
                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    @click="updateChangedValues"
                    :loading="saving"
                    :disabled="disabled"
                  >
                    {{ $t('update') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import YessPartnerListItem from "@/components/yess/YessPartnerListItem"
export default {
	name: "YessDiscounts",
	components: {
		YessPartnerListItem
	},
	data() {
		return {
			selectedPartners: []
		}
	},
	computed: {
		isGettingYessPartners() {
			return this.$store.getters.isGettingYessPartners
		},
		isGettingCurrentYessPartnerDiscounts() {
			return this.$store.getters.isGettingCurrentYessPartnerDiscounts
		},
		loading() {
			return this.isGettingYessPartners || this.isGettingCurrentYessPartnerDiscounts
		},
		partners() {
			return this.$store.getters.yessPartners
		},
		currentYessPartnerDiscounts() {
			return this.$store.getters.currentOrganizationYessPartnerDiscounts
		},
		saving() {
			return this.$store.getters.isSavingYessPartnerDiscounts
		},
		disabled() {
			return this.$store.getters.isSavingYessPartnerDiscounts
		}
	},
	methods: {
		updateChangedValues() {
			this.$store.dispatch('saveYessPartnerDiscounts', this.partners)
		},
		reload() {
			this.$store.dispatch('getYessPartners').then(() => {
				this.$store.dispatch('getCurrentYessPartnerDiscounts')
			})
		}
	},
	beforeMount() {
		this.$store.dispatch('getYessPartners').then(() => {
			this.$store.dispatch('getCurrentYessPartnerDiscounts')
		})
	}
}
</script>

<style scoped>

</style>